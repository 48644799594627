import { Injectable } from '@angular/core';
import { AppState } from '../ngrx';
import { Store } from '@ngrx/store';
import { HcAirlinesError } from '../models/hc-airlines-error';
import { ErrorCode } from '../enums/error-code.enum';
import { ResponseError } from '@hts/partner-airlines-api-client';
import { setSessionExpired } from '../ngrx/global/global.actions';
import { LoggerService } from './logger.service';

const HTTP_STATUS_UNAUTHORIZED = 401;

@Injectable()
export class ErrorService {
  constructor(
    private _store: Store<AppState>,
    private _loggerService: LoggerService,
  ) {}

  async handleRequestError(value: unknown) {
    let message = 'unexpected request error';
    let code = ErrorCode.UNKNOWN_ERROR;

    // Includes instanceof Error
    if (!(value instanceof ResponseError)) {
      message = `${message}: ${value}`;
      return new HcAirlinesError(message, code);
    }

    const { response } = value;

    const { status } = response;

    if (status === HTTP_STATUS_UNAUTHORIZED) {
      this._loggerService.info('The session has expired');
      this._store.dispatch(setSessionExpired({ sessionExpired: true }));
    }

    const contentType = response.headers.get('content-type');
    message = `unknown failure: status ${status}`;

    if (contentType === 'application/json') {
      const { errors } = await response.json();
      if (Array.isArray(errors) && errors.length > 0) {
        [{ message, code }] = errors;
      }
    } else {
      const responseText = await response.text();
      if (responseText.trim().length > 0) {
        message = responseText;
      }
    }

    return new HcAirlinesError(message, code);
  }
}
