import { createAction, props } from '@ngrx/store';

export const setCfarShowPaymentMethodDisclaimer = createAction(
  '[FeatureFlag] Set the cfarShowPaymentMethodDisclaimer boolean',
  props<{ cfarShowPaymentMethodDisclaimer: boolean }>(),
);

export const setCfarShowGoodwillBanner = createAction(
  '[FeatureFlag] Set the cfarShowGoodwillBanner boolean',
  props<{ cfarShowGoodwillBanner: boolean }>(),
);
