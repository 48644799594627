import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Theme } from '../../enums/theme.enum';

import { AppState } from '../../ngrx';
import * as globalActions from '../../ngrx/global/global.actions';
import {
  getCurrentLang,
  getCurrentRedirectbackUrl,
  getCurrentTheme,
  getIsRedirectbackButtonVisible,
  getPartnerName,
} from '../../ngrx/global/global.selectors';
import { LoggerService } from '../../services/logger.service';
import { createRedirectUrl } from '../../utils/createRedirectUrl';
import { Observable, Subject } from 'rxjs';
import { CustomLocale } from '../../enums/language-code.enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavBarComponent implements OnInit {
  public currentRedirectbackUrl!: string;
  public currentTheme!: string;
  public languages!: CustomLocale[];
  public themes!: Theme[];
  public isProd!: boolean;

  partnerName$ = this._store.select(getPartnerName);
  currentLang$ = this._store.select(getCurrentLang);

  private _unsubscriber = new Subject();

  public isRedirectbackButtonVisible$!: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    private _loggerService: LoggerService,
  ) {
    this.isProd = environment.production;

    this.languages = Object.values(CustomLocale);
    this.themes = Object.values(Theme);
  }

  // -------------------------------
  // - LIFE CYCLE HOOKS
  // -------------------------------

  ngOnInit(): void {
    this._store
      .pipe(select(getCurrentRedirectbackUrl), takeUntil(this._unsubscriber))
      .subscribe(
        (currentRedirectbackUrl: string) =>
          (this.currentRedirectbackUrl = currentRedirectbackUrl),
      );

    this._store
      .pipe(select(getCurrentTheme), takeUntil(this._unsubscriber))
      .subscribe((currentTheme: Theme) => {
        // Remove old theme
        document.body.classList.remove(this.currentTheme);

        // Add the new theme
        this.currentTheme = currentTheme;
        document.body.classList.add(currentTheme);
      });

    this.isRedirectbackButtonVisible$ = this._store.select(
      getIsRedirectbackButtonVisible,
    );
  }

  // -------------------------------
  // - PUBLICS METHODS
  // -------------------------------

  onSetTheme(newTheme: Theme): void {
    this._store.dispatch(
      globalActions.setCurrentTheme({ currentTheme: newTheme }),
    );
  }

  onSetLanguage(newLanguage: CustomLocale): void {
    this._store.dispatch(
      globalActions.setCurrentLang({ currentLang: newLanguage }),
    );
  }

  onRedirectBackToPartnerWebsite(): void {
    try {
      this._loggerService.info(
        `redirecting user to partner-provided redirectbackUrl: ${this.currentRedirectbackUrl}`,
      );
      location.href = createRedirectUrl(this.currentRedirectbackUrl);
    } catch (error) {
      this._loggerService.warn(
        'Cannot decode the URI: ' + this.currentRedirectbackUrl,
      );
    }
  }

  onImgError(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
  }

  onImgLoad(event: Event) {
    (event.target as HTMLImageElement).style.display = 'initial';
  }

  /**
   * Used in order to manage differents img formats (png, svg, ...)
   * @returns
   */
  getPartnerLogo(): string {
    let logoPath: string = `/assets/themes/${this.currentTheme}/logo`;
    let extension: string = '.png';

    switch (this.currentTheme) {
      case Theme.THEME_LIGHT_VIRGIN_AUSTRALIA:
      case Theme.THEME_LIGHT_VOLARIS:
        extension = '.svg';
        break;
      case Theme.THEME_LIGHT_AIR_CANADA:
        return '';
    }

    return logoPath + extension;
  }

  getDisplayedLanguageCode(lang: string): string {
    return lang.substring(0, 2).toUpperCase();
  }

  isDevEnvironment(): boolean {
    return environment.local || environment.development || environment.mock;
  }
}
