import { Injectable, OnDestroy } from '@angular/core';
import { Subject, from, takeUntil } from 'rxjs';
import {
  ExerciseStepResult,
  Configuration,
  AnalyticsApi,
  CfarEvent,
} from '@hts/partner-airlines-api-client';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '../ngrx';

@Injectable()
export class HopperEventsService implements OnDestroy {
  private _client!: AnalyticsApi;
  private _unsubcriber = new Subject();
  private _exerciseId = '';

  constructor(private _store: Store<AppState>) {
    this._store
      .pipe(
        select(({ global: { currentSessionId, currentExerciseId } }) => ({
          currentExerciseId,
          currentSessionId,
        })),
        takeUntil(this._unsubcriber),
      )
      .subscribe(({ currentExerciseId, currentSessionId }) => {
        const configuration = new Configuration({
          apiKey: currentSessionId,
          basePath: environment.apiUrl,
        });
        this._client = new AnalyticsApi(configuration);
        this._exerciseId = currentExerciseId;
      });
  }

  ngOnDestroy(): void {
    this._unsubcriber.next(null);
    this._unsubcriber.complete();
  }

  // -----------------------------------------------
  // Private Methods
  // -----------------------------------------------

  private _postCustomerEventRequest(event: CfarEvent) {
    return from(
      this._client.postCustomerEvents({
        cfarEvent: event,
      }),
    );
  }

  // -----------------------------------------------
  // Public Methods : CFAR UI events
  // ----------------------------------------------------------
  createCfarExercisePortalDisplayEvent() {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      type: 'cfar_exercise_portal_display' as const,
    });
  }

  createCfarExerciseVerificationSentEvent() {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      type: 'cfar_exercise_verification_sent' as const,
    });
  }

  createCfarExerciseVerificationCompleteEvent(
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      result: exerciseStepResult,
      type: 'cfar_exercise_verification_complete' as const,
    });
  }

  createCfarExerciseCustomerDataCompleteEvent(
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      result: exerciseStepResult,
      type: 'cfar_exercise_customer_data_complete' as const,
    });
  }

  createCfarExercisePortalCompleteEvent(
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      result: exerciseStepResult,
      type: 'cfar_exercise_portal_complete' as const,
    });
  }

  createCfarExerciseCallbackLaunchedEvent(
    exerciseStepResult: ExerciseStepResult,
  ) {
    return this._postCustomerEventRequest({
      occurredDateTime: new Date(),
      cfarExerciseId: this._exerciseId,
      result: exerciseStepResult,
      type: 'cfar_exercise_callback_launched' as const,
    });
  }
}
