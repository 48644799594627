import { Action, createReducer, on } from '@ngrx/store';
import * as globalActions from './global.actions';
import { environment } from '../../../../environments/environment';
import { Partner } from '../../enums/partner.enum';
import { CustomLocale } from '../../enums/language-code.enum';

const initialState = {
  currentLang: CustomLocale.EN_US,
  currentTheme: environment.defaultTheme,
  currentSessionId: '',
  currentExerciseId: '',
  currentCallbackUrl: '',
  currentRedirectbackUrl: '',
  partnerId: Partner.HTS_AIRLINE,
  partnerName: '',
  contractId: '',
  isRedirectbackButtonVisible: true,
  isLoadingConfiguration: true,
  sessionExpired: false,
};

export type GlobalState = typeof initialState;

const reducer = createReducer(
  initialState,
  on(globalActions.setContractId, (state, { contractId }) => ({
    ...state,
    contractId,
  })),
  on(globalActions.setCurrentLang, (state, { currentLang }) => ({
    ...state,
    currentLang,
  })),
  on(globalActions.setCurrentTheme, (state, { currentTheme }) => ({
    ...state,
    currentTheme,
  })),
  on(globalActions.setCurrentExerciseId, (state, { currentExerciseId }) => ({
    ...state,
    currentExerciseId,
  })),
  on(globalActions.setCurrentSessionId, (state, { currentSessionId }) => ({
    ...state,
    currentSessionId,
  })),
  on(globalActions.setCurrentCallbackUrl, (state, { currentCallbackUrl }) => ({
    ...state,
    currentCallbackUrl,
  })),
  on(
    globalActions.setCurrentRedirectbackUrl,
    (state, { currentRedirectbackUrl }) => ({
      ...state,
      currentRedirectbackUrl,
    }),
  ),
  on(globalActions.setPartnerId, (state, { partnerId }) => ({
    ...state,
    partnerId,
  })),
  on(globalActions.setPartnerName, (state, { partnerName }) => ({
    ...state,
    partnerName,
  })),
  on(
    globalActions.setIsRedirectbackButtonVisible,
    (state, { isRedirectbackButtonVisible }) => ({
      ...state,
      isRedirectbackButtonVisible,
    }),
  ),
  on(
    globalActions.setIsLoadingConfiguration,
    (state, { isLoadingConfiguration }) => ({
      ...state,
      isLoadingConfiguration,
    }),
  ),
  on(globalActions.setSessionExpired, (state, { sessionExpired }) => ({
    ...state,
    sessionExpired,
  })),
);

export function globalReducer(
  state: GlobalState | undefined,
  action: Action,
): GlobalState {
  return reducer(state, action);
}
