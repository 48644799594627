/**
 * Non exaustive list of error managed by the API
 */
export enum ErrorCode {
  // The verification code is not valid.
  VERIFICATION_CODE_INVALID = 'EX019',
  // The verification code has already been sent.
  VERIFICATION_CODE_ALREADY_SENT = 'EX037',
  // Unknown error.
  UNKNOWN_ERROR = 'RF009',

  // The contract is not eligible for automatic exercise.
  CONTRACT_INELIGIBLE_AUTO_EXERCISE = 'EX165',

  // Urgently contact the support team.
  URGENT_CONTACT_SUPPORT = 'EX000',
}
