import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalState } from './global.reducer';

export const getGlobalState = createFeatureSelector<GlobalState>('global');
export const getCurrentLang = createSelector(
  getGlobalState,
  (state) => state.currentLang,
);
export const getCurrentTheme = createSelector(
  getGlobalState,
  (state) => state.currentTheme,
);
export const getCurrentSessionId = createSelector(
  getGlobalState,
  (state) => state.currentSessionId,
);

export const getCurrentExerciseId = createSelector(
  getGlobalState,
  ({ currentExerciseId }) => currentExerciseId,
);

export const getCurrentCallbackUrl = createSelector(
  getGlobalState,
  ({ currentCallbackUrl }) => currentCallbackUrl,
);

export const getCurrentRedirectbackUrl = createSelector(
  getGlobalState,
  ({ currentRedirectbackUrl }) => currentRedirectbackUrl,
);
export const getPartnerId = createSelector(
  getGlobalState,
  ({ partnerId }) => partnerId,
);
export const getPartnerName = createSelector(
  getGlobalState,
  ({ partnerName }) => partnerName,
);

export const getContractId = createSelector(
  getGlobalState,
  ({ contractId }) => contractId,
);

export const getIsRedirectbackButtonVisible = createSelector(
  getGlobalState,
  ({ isRedirectbackButtonVisible }) => isRedirectbackButtonVisible,
);

export const getIsLoadingConfiguration = createSelector(
  getGlobalState,
  ({ isLoadingConfiguration }) => isLoadingConfiguration,
);

export const getSessionExpired = createSelector(
  getGlobalState,
  ({ sessionExpired }) => sessionExpired,
);
