import { Locales } from '../i18n';
import { Locale as DateFnsLocale } from 'date-fns';
import { CustomLocale } from '../enums/language-code.enum';

export const dateFnsLocales: Record<
  CustomLocale,
  () => Promise<{ default: DateFnsLocale }>
> = {
  'en-us': () => import(`@/../node_modules/date-fns/locale/en-US/index.js`),
  'en-au': () => import(`@/../node_modules/date-fns/locale/en-AU/index.js`),
  es: () => import(`@/../node_modules/date-fns/locale/es/index.js`),
  'fr-ca': () => import(`@/../node_modules/date-fns/locale/fr/index.js`),
  id: () => import(`@/../node_modules/date-fns/locale/id/index.js`),
  ja: () => import(`@/../node_modules/date-fns/locale/ja/index.js`),
  'km-kh': () => import(`@/../node_modules/date-fns/locale/km/index.js`),
  ko: () => import(`@/../node_modules/date-fns/locale/ko/index.js`),
  ms: () => import(`@/../node_modules/date-fns/locale/ms/index.js`),
  ru: () => import(`@/../node_modules/date-fns/locale/ru/index.js`),
  th: () => import(`@/../node_modules/date-fns/locale/th/index.js`),
  tr: () => import(`@/../node_modules/date-fns/locale/tr/index.js`),
  vi: () => import(`@/../node_modules/date-fns/locale/vi/index.js`),
  'zh-cn': () => import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  'zh-hans': () => import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  'zh-hant': () => import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  'zh-hk': () => import(`@/../node_modules/date-fns/locale/zh-HK/index.js`),
};

export function getLanguageFromSession(
  language: string,
  pointOfSale: string,
): CustomLocale {
  // make sure everything is in lower case
  const formattedLang = language.toLowerCase();
  const formattedPoS = pointOfSale.toLowerCase();
  const availableLocales = Array.from(Locales.keys()).map((key) =>
    key.toLowerCase(),
  );
  const languageCode = `${formattedLang}-${formattedPoS}`;

  if (availableLocales.includes(languageCode as CustomLocale)) {
    return languageCode as CustomLocale;
  } else if (availableLocales.includes(formattedLang as CustomLocale)) {
    return formattedLang as CustomLocale;
  } else {
    // FIXME : FE mapping, to delete later
    switch (formattedLang) {
      case 'en':
      case 'en_us':
        return CustomLocale.EN_US;
      case 'en_au':
        return CustomLocale.EN_AU;
      case 'fr':
      case 'fr_fr':
      case 'fr_ca':
        return CustomLocale.FR_CA;
      case 'zh':
      case 'zh_cn':
        return CustomLocale.ZH_CN;
      case 'zh_hans':
        return CustomLocale.ZH_HANS;
      case 'zh_hant':
        return CustomLocale.ZH_HANT;
      case 'zh_hk':
        return CustomLocale.ZH_HK;
      default: {
        return CustomLocale.EN_US;
      }
    }
  }
}

/**
 * Expected format for Hyperwallet : 2 letters in lowercase.
 * Eg. 'en'
 */
export function getHWLocaleFromLocale(locale: CustomLocale): string {
  // Eg. 'en-us' > 'en'
  return locale.toString().slice(0, 2);
}

/**
 * Expected format for Angular
 * > See @/../node_modules/@angular/common/locales folder
 */
export function getAngularLocalefromLocale(locale: CustomLocale): string {
  let angularLocale = locale as string;

  // Special cases
  switch (locale) {
    case CustomLocale.EN_US:
      angularLocale = 'en';
      break;
    case CustomLocale.EN_AU:
      angularLocale = 'en-AU';
      break;
    case CustomLocale.FR_CA:
      angularLocale = 'fr-CA';
      break;
    case CustomLocale.ZH_CN:
    case CustomLocale.ZH_HK:
    case CustomLocale.ZH_HANT:
      angularLocale = 'zh';
      break;
    case CustomLocale.ZH_HANS:
      angularLocale = 'zh-Hans';
      break;
    case CustomLocale.KM_KH:
      angularLocale = 'km';
      break;
  }

  return angularLocale || 'en';
}
