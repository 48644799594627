import { Action, createReducer, on } from '@ngrx/store';
import * as featureFlagActions from './feature-flag.actions';

const initialState = {
  cfarShowPaymentMethodDisclaimer: false,
  cfarShowGoodwillBanner: false,
};

export type FeatureFlagState = typeof initialState;

const reducer = createReducer(
  initialState,
  on(
    featureFlagActions.setCfarShowPaymentMethodDisclaimer,
    (state, { cfarShowPaymentMethodDisclaimer }) => ({
      ...state,
      cfarShowPaymentMethodDisclaimer,
    }),
  ),
  on(
    featureFlagActions.setCfarShowGoodwillBanner,
    (state, { cfarShowGoodwillBanner }) => ({
      ...state,
      cfarShowGoodwillBanner,
    }),
  ),
);

export function featureFlagReducer(
  state: FeatureFlagState | undefined,
  action: Action,
): FeatureFlagState {
  return reducer(state, action);
}
