import { createAction, props } from '@ngrx/store';
import { Theme } from '../../enums/theme.enum';
import { Partner } from '../../enums/partner.enum';
import { CustomLocale } from '../../enums/language-code.enum';

export const setCurrentLang = createAction(
  '[Global] Set the current language',
  props<{ currentLang: CustomLocale }>(),
);

export const setCurrentTheme = createAction(
  '[Global] Set the current theme',
  props<{ currentTheme: Theme }>(),
);

export const setCurrentCallbackUrl = createAction(
  '[Global] Set the current callbackUrl',
  props<{ currentCallbackUrl: string }>(),
);

export const setCurrentRedirectbackUrl = createAction(
  '[Global] Set the current redirectbackUrl',
  props<{ currentRedirectbackUrl: string }>(),
);

export const setCurrentSessionId = createAction(
  '[Global] Set the current session id',
  props<{ currentSessionId: string }>(),
);

export const setSessionExpired = createAction(
  '[Global] Set the Session expired state',
  props<{ sessionExpired: boolean }>(),
);

export const setCurrentExerciseId = createAction(
  '[Global] Set the current exercise id',
  props<{ currentExerciseId: string }>(),
);

export const setPartnerId = createAction(
  '[Global] Set the partner id',
  props<{ partnerId: Partner }>(),
);

export const setPartnerName = createAction(
  '[Global] Set the partner name',
  props<{ partnerName: string }>(),
);

export const setContractId = createAction(
  '[Global] Set the contract id',
  props<{ contractId: string }>(),
);

export const setIsRedirectbackButtonVisible = createAction(
  '[Global] Set the isRedirectbackButtonVisible boolean',
  props<{ isRedirectbackButtonVisible: boolean }>(),
);

export const setIsLoadingConfiguration = createAction(
  '[Global] Set the isLoadingConfiguration boolean',
  props<{ isLoadingConfiguration: boolean }>(),
);
