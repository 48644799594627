import { Injectable, OnDestroy } from '@angular/core';
import { Subject, from, takeUntil } from 'rxjs';
import {
  CancelForAnyReasonCFARApi,
  CheckCfarContractExerciseVerificationCodeRequest,
  Configuration,
  CreateCfarContractCustomerRequest,
  CreateCfarOfferCustomerRequest,
  CreateRefundAuthorizationRequest,
  CreateRefundRecipientRequest,
  InitiateRefundRequest,
} from '@hts/partner-airlines-api-client';
import { environment } from 'src/environments/environment';
import { Store, createSelector, select } from '@ngrx/store';
import { AppState } from '../ngrx';
import {
  getCurrentExerciseId,
  getCurrentLang,
  getCurrentSessionId,
} from '../ngrx/global/global.selectors';
import { setSessionExpired } from '../ngrx/global/global.actions';
import { LoggerService } from './logger.service';

const dataSelector = createSelector(
  getCurrentExerciseId,
  getCurrentLang,
  (exerciseId, language) => ({ exerciseId, language }),
);

@Injectable()
export class HopperAirlinesService implements OnDestroy {
  private _client!: CancelForAnyReasonCFARApi;
  private _unsubcriber = new Subject();
  private _exerciseId = '';
  private _language = '';

  constructor(
    private _loggerService: LoggerService,
    private _store: Store<AppState>,
  ) {
    this._store
      .pipe(select(getCurrentSessionId), takeUntil(this._unsubcriber))
      .subscribe((sessionId) => {
        const configuration = new Configuration({
          apiKey: sessionId,
          basePath: environment.apiUrl,
        });
        this._client = new CancelForAnyReasonCFARApi(configuration);
      });

    this._store.select(dataSelector).subscribe(({ language, exerciseId }) => {
      this._exerciseId = exerciseId;
      this._language = language;
    });
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngOnDestroy(): void {
    this._unsubcriber.next(null);
    this._unsubcriber.complete();
  }

  // -----------------------------------------------
  // Publics
  // -----------------------------------------------
  getRetrieveCustomerSessionRaw() {
    return this._client.getCustomerSessionsRetrieveRaw();
  }

  postCfarOffers(
    createCfarOfferCustomerRequest: CreateCfarOfferCustomerRequest,
  ) {
    return this._client.postCustomerCfarOffers({
      createCfarOfferCustomerRequest,
      language: this._language,
    });
  }

  postCfarContracts(
    createCfarContractCustomerRequest: CreateCfarContractCustomerRequest,
  ) {
    return this._client.postCustomerCfarContracts({
      createCfarContractCustomerRequest,
      language: this._language,
    });
  }

  getCfarExercises(verificationCode: string) {
    return from(
      this._client.getCustomerCfarExercises({
        verificationCode,
      }),
    );
  }

  postRefundAuthorizations(
    verificationCode: string,
    createRefundAuthorizationRequest: CreateRefundAuthorizationRequest,
  ) {
    return this._client.postCustomerRefundAuthorizations({
      createRefundAuthorizationRequest,
      verificationCode,
    });
  }

  postRefundRecipients(
    verificationCode: string,
    createRefundRecipientRequest: CreateRefundRecipientRequest,
  ) {
    return from(
      this._client.postCustomerRefundRecipients({
        createRefundRecipientRequest,
        verificationCode,
      }),
    );
  }

  getExercisePayoutCurrencyConversion(
    id: string,
    verificationCode: string,
    targetCurrency: string,
  ) {
    return this._client.getCustomerCfarExercisesIdPayoutCurrencyConversion({
      id: id,
      verificationCode,
      targetCurrency,
    });
  }

  postInitiateRefund(
    verificationCode: string,
    initiateRefundRequest: InitiateRefundRequest,
  ) {
    return this._client.postCustomerInitiateRefund({
      initiateRefundRequest,
      verificationCode,
    });
  }

  postSendCfarExerciseVerificationCode(body: object) {
    return this._client.postCustomerIdSendExerciseVerificationCode({
      id: this._exerciseId,
      body,
    });
  }

  postCheckCfarExerciseVerificationCode(
    checkCfarContractExerciseVerificationCodeRequest: CheckCfarContractExerciseVerificationCodeRequest,
  ) {
    return this._client.postCustomerIdCheckExerciseVerificationCode({
      id: this._exerciseId,
      checkCfarContractExerciseVerificationCodeRequest,
    });
  }
}
