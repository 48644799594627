import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

async function initializeWorker() {
  if (environment.mock) {
    const { worker } = await import('./app/shared/apis/mock/browser');
    const excludedPatterns = [
      /^\/assets/,
      /https:\/\/uat-api.paylution.com/,
      /https:\/\/browser-intake-us5-datadoghq.com/,
    ];
    return worker.start({
      onUnhandledRequest(req, print) {
        if (excludedPatterns.some((regex) => req.url.match(regex))) {
          return;
        }
        print.warning();
      },
    });
  }
  return Promise.resolve();
}

initializeWorker().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
