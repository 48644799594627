import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationResolverService implements Resolve<any> {
  constructor(private _translateService: TranslateService) {}

  resolve(): Observable<any> {
    return this._translateService.getTranslation(
      this._translateService.currentLang,
    );
  }
}
