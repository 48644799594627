export enum CustomLocale {
  EN_US = 'en-us',
  EN_AU = 'en-au',
  ES = 'es',
  FR_CA = 'fr-ca',
  ID = 'id',
  JA = 'ja',
  KM_KH = 'km-kh',
  KO = 'ko',
  MS = 'ms',
  RU = 'ru',
  TH = 'th',
  TR = 'tr',
  VI = 'vi',
  ZH_CN = 'zh-cn',
  ZH_HANS = 'zh-hans',
  ZH_HANT = 'zh-hant',
  ZH_HK = 'zh-hk',
}
