import { Theme } from 'src/app/shared/enums/theme.enum';
import packageFile from '../../package.json';

export const environment = {
  production: false,
  staging: true,
  development: false,
  local: false,
  mock: false,
  defaultTheme: Theme.THEME_LIGHT_AIR_CANADA,
  apiUrl: 'https://airlines-api.staging.hopper.com/airline/v1.1',
  hyperwalletUrl:
    'https://uat-api.paylution.com/rest/widgets/transfer-methods/',
  version: packageFile.version,
};
