import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { reducers } from './shared/ngrx';
import { TranslateModule } from '@ngx-translate/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_DATE_FNS_FORMATS,
  DateFnsAdapter,
} from '@angular/material-date-fns-adapter';
import { SharedModule } from './shared/shared.module';
import { HopperAirlinesService } from './shared/services/hopper-airlines.service';
import { LoggerService } from './shared/services/logger.service';
import { HopperEventsService } from './shared/services/hopper-events.service';
import { LocationI18nService } from './shared/services/location-i18n.service';
import { HyperwalletService } from './shared/services/hyperwallet.service';
import { ErrorService } from './shared/services/error.service';

const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storeFreeze]
  : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,

    // Custom Modules
    AppRoutingModule,
    SharedModule,

    // Angular Material Modules
    MatButtonModule,
    MatIconModule,

    // Misc Modules
    FlexLayoutModule,
    TranslateModule.forRoot(),

    // Ngrx Modules
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    HopperAirlinesService,
    HopperEventsService,
    LoggerService,
    LocationI18nService,
    HyperwalletService,
    ErrorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
