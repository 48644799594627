<mat-toolbar
  class="mat-elevation-z4"
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngIf="currentLang$ | async as currentLang"
>
  <div
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <ng-container
        *ngIf="currentRedirectbackUrl && (isRedirectbackButtonVisible$ | async)"
      >
        <button
          mat-mini-fab
          (click)="onRedirectBackToPartnerWebsite()"
          attr.aria-label="{{
            'NAVBAR.BUTTON.GO_BACK_TO_PARTNER'
              | translate
                : {
                    partnerName: (partnerName$ | async),
                  }
          }}"
          matTooltip="{{
            'NAVBAR.BUTTON.GO_BACK_TO_PARTNER'
              | translate
                : {
                    partnerName: (partnerName$ | async),
                  }
          }}"
          class="mr-16"
        >
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </ng-container>

      <img
        fxHide.lt-sm
        class="airline-logo"
        src="{{ getPartnerLogo() }}"
        (error)="onImgError($event)"
        (load)="onImgLoad($event)"
      />
    </div>

    <ng-container *ngIf="isDevEnvironment()">
      <button
        mat-button
        [matMenuTriggerFor]="themeMenu"
        aria-label="Open Menu"
        *ngIf="currentTheme"
      >
        <mat-icon class="mr-8">palette</mat-icon>
        <span fxHide.lt-md>{{ currentTheme }}</span>
      </button>

      <mat-menu #themeMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let theme of themes"
          attr.aria-label="Apply {{ theme }} theme"
          (click)="onSetTheme(theme)"
        >
          <mat-icon>palette</mat-icon>
          <span>{{ theme }}</span>
        </button>
      </mat-menu>
    </ng-container>

    <button
      mat-button
      [matMenuTriggerFor]="languageMenu"
      ariaLabel="Language Selection"
    >
      <span>{{
        'COMMON.LOCALE.' + currentLang.toUpperCase() | translate
      }}</span>
    </button>

    <mat-menu #languageMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of languages"
        (click)="onSetLanguage(language)"
        attr.aria-label="Set the current language to {{ language }}"
      >
        <span>{{
          getDisplayedLanguageCode(language) +
            ' - ' +
            ('COMMON.LOCALE.' + language.toUpperCase() | translate)
        }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
