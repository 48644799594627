import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NavBarComponent } from './components/navbar/navbar.component';
import { CfarExerciseFlowComponent } from './components/cfar-exercise-flow/cfar-exercise-flow.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SessionExpiredDialogComponent } from './components/session-expired-dialog/session-expired-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    NavBarComponent,
    LayoutComponent,
    CfarExerciseFlowComponent,
    SessionExpiredDialogComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    RouterModule,
    HttpClientModule,

    // Angular Material Modules
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,

    // Misc Modules
    FlexLayoutModule,
    TranslateModule.forChild(),
  ],
  exports: [
    NavBarComponent,
    LayoutComponent,
    CfarExerciseFlowComponent,
    SessionExpiredDialogComponent,
  ],
  providers: [],
})
export class SharedModule {}
