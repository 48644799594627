export function createRedirectUrl(url: string, redirectionToken?: string) {
  const decodedUrl = decodeURIComponent(url);

  if (redirectionToken) {
    // Concatenate the redirection token to the given url
    return decodedUrl + '&redirectionToken=' + redirectionToken;
  }

  return decodedUrl;
}
