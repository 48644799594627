import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HWWidgets } from '../apis/hyperwallet/types';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AppState } from '../ngrx';
import { LoggerService } from './logger.service';
import { getCurrentLang } from '../ngrx/global/global.selectors';
import { getHWLocaleFromLocale } from '../utils/LocaleUtils';
import { CustomLocale } from '../enums/language-code.enum';

export enum HyperWalletState {
  Idle,
  Loading,
  Loaded,
  Error,
}

@Injectable()
export class HyperwalletService implements OnDestroy {
  private unsubcriber = new Subject();
  private hyperwallet: Subject<HWWidgets>;
  private currentLang = CustomLocale.EN_US as string;
  public hyperwallet$: Observable<HWWidgets> = new Observable();
  public loadState: Subject<HyperWalletState> = new Subject();

  constructor(
    private _store: Store<AppState>,
    private _loggerService: LoggerService,
  ) {
    this.hyperwallet = new Subject<HWWidgets>();
    this.hyperwallet$ = this.hyperwallet.asObservable();
    this.loadState.next(HyperWalletState.Idle);

    this._store
      .pipe(select(getCurrentLang), takeUntil(this.unsubcriber))
      .subscribe(
        (lang: CustomLocale) =>
          (this.currentLang = getHWLocaleFromLocale(lang)),
      );
  }

  injectScript(userId: string) {
    this.loadState.next(HyperWalletState.Loading);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `${environment.hyperwalletUrl}${userId}/${this.currentLang}.v2_4_5.min.js`;
    script.onerror = (error) => {
      this._loggerService.error(`Hyperwallet script load error: ${error}`);
      this.loadState.next(HyperWalletState.Error);
    };
    script.onload = () => {
      this.hyperwallet.next(window.HWWidgets);
      this.loadState.next(HyperWalletState.Loaded);
    };
    document.head.appendChild(script);
  }

  ngOnDestroy(): void {
    this.unsubcriber.next(null);
    this.unsubcriber.complete();
  }
}
