import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../ngrx';
import { selectRouteData } from '../../ngrx/router/router.actions';
import { Subject } from 'rxjs';
import {
  getCurrentLang,
  getIsLoadingConfiguration,
} from '../../ngrx/global/global.selectors';
import { Locales } from '../../i18n';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';
import { setCurrentLang } from '../../ngrx/global/global.actions';
import { registerLocaleData } from '@angular/common';
import {
  dateFnsLocales,
  getAngularLocalefromLocale,
} from '../../utils/LocaleUtils';
import { CustomLocale } from '../../enums/language-code.enum';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public selectedPath!: string;

  public currentLang$ = this._store.select(getCurrentLang);
  public isLoadingConfiguration$ = this._store.select(
    getIsLoadingConfiguration,
  );

  private _unsubscriber = new Subject();

  constructor(
    private _store: Store<AppState>,
    private _adapter: DateAdapter<any>,
    private _translateService: TranslateService,
    private _loggerService: LoggerService,
  ) {
    // Set Labels
    Locales.forEach((data: Object, lang: string) => {
      this._translateService.setTranslation(lang, data, true);
    });

    // Set availables languages
    const availableLocales = Array.from(Locales.keys());
    this._translateService.addLangs(availableLocales);

    // Set default language
    let defaultLang = this._translateService.getBrowserCultureLang() || 'en-US';

    if (!availableLocales.includes(defaultLang as CustomLocale)) {
      defaultLang = CustomLocale.EN_US;
    }

    // Set the current lang in the Store
    this._store.dispatch(
      setCurrentLang({ currentLang: defaultLang as CustomLocale }),
    );
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngOnInit() {
    // Update the selectedPath when it's changing
    this._store
      .pipe(select(selectRouteData), takeUntil(this._unsubscriber))
      .subscribe((data: Data) => (this.selectedPath = data.path));

    // update the currentLang when it's changing
    this._store
      .pipe(select(getCurrentLang), takeUntil(this._unsubscriber))
      .subscribe((currentLanguage: CustomLocale) => {
        // Set language in differents sources
        this._loadAngularCommonLocale(currentLanguage);
        this._loadDateFnsLocale(currentLanguage);
        this._translateService.use(currentLanguage);
      });
  }

  ngOnDestroy() {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // -------------------------------
  // - PUBLICS METHODS
  // -------------------------------

  onScrollToTop(event: any): void {
    document.querySelector('#scroll-anchor')?.scrollTo(0, 0);
  }

  // -------------------------------
  // - Privates
  // -------------------------------

  private _loadDateFnsLocale = (locale: CustomLocale) => {
    const targetModule =
      locale in dateFnsLocales
        ? dateFnsLocales[locale]
        : dateFnsLocales[CustomLocale.EN_US];

    targetModule().then((module) => {
      this._adapter.setLocale(module.default);
    });
  };

  private _loadAngularCommonLocale = (lang: CustomLocale) => {
    let angularLocale = getAngularLocalefromLocale(lang);

    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-extra" */
      `@/../node_modules/@angular/common/locales/${angularLocale}.mjs`
    ).then((module) => registerLocaleData(module.default));
  };
}
