// http://www.loc.gov/standards/iso639-2/php/code_list.php

import { CustomLocale } from '../enums/language-code.enum';

import * as AmericanEnglish from './locales/en-us/common.json';
import * as AustralianEnglish from './locales/en-au/common.json';
import * as Spanish from './locales/es/common.json';
import * as CanadianFrench from './locales/fr-ca/common.json';
import * as Indonesian from './locales/id/common.json';
import * as Japanese from './locales/ja/common.json';
import * as Khmer from './locales/km-kh/common.json';
import * as Korean from './locales/ko/common.json';
import * as Malay from './locales/ms/common.json';
import * as Russian from './locales/ru/common.json';
import * as Thai from './locales/th/common.json';
import * as Turkish from './locales/tr/common.json';
import * as Vietnamese from './locales/vi/common.json';
import * as ChineseCN from './locales/zh-cn/common.json';
import * as ChineseHans from './locales/zh-hans/common.json';
import * as ChineseHant from './locales/zh-hant/common.json';
import * as ChineseHK from './locales/zh-hk/common.json';

// Availables Languages
export const Locales = new Map<CustomLocale, any>([
  [CustomLocale.EN_US, AmericanEnglish],
  [CustomLocale.EN_AU, AustralianEnglish],
  [CustomLocale.ES, Spanish],
  [CustomLocale.FR_CA, CanadianFrench],
  [CustomLocale.ID, Indonesian],
  [CustomLocale.JA, Japanese],
  [CustomLocale.KM_KH, Khmer],
  [CustomLocale.KO, Korean],
  [CustomLocale.MS, Malay],
  [CustomLocale.RU, Russian],
  [CustomLocale.TH, Thai],
  [CustomLocale.TR, Turkish],
  [CustomLocale.VI, Vietnamese],
  [CustomLocale.ZH_CN, ChineseCN],
  [CustomLocale.ZH_HANS, ChineseHans],
  [CustomLocale.ZH_HANT, ChineseHant],
  [CustomLocale.ZH_HK, ChineseHK],
]);
